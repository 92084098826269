.Filters {
  overflow: auto;
  margin: 0 -40px -20px;
  height: 350px;
}
.FiltersContent {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding-left: 50px;
}

.PeekAnimation {
  transition: opacity 1000ms;
  -webkit-animation: autoPeek ease-in-out 2s alternate;
          animation: autoPeek ease-in-out 2s alternate;
  -webkit-animation-iteration-count: 2;
          animation-iteration-count: 2;
}
/* For auto scroll. */
@-webkit-keyframes autoPeek {
  0% {
    margin-left: 0;
    transform: translate3d(0, 0, 0);
  }
  75% {
    margin-left: 0;
    transform: translate3d(0, 0, 0);
  }
  100% {
    margin-left: 0;
    transform: translate3d(-200px, 0, 0);
  }
}
@keyframes autoPeek {
  0% {
    margin-left: 0;
    transform: translate3d(0, 0, 0);
  }
  75% {
    margin-left: 0;
    transform: translate3d(0, 0, 0);
  }
  100% {
    margin-left: 0;
    transform: translate3d(-200px, 0, 0);
  }
}

.Filters .scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #666;
  position: absolute;
  cursor: -webkit-grabbing;
  cursor: grabbing;
  bottom: 10px;
  left: 10px;
  opacity: 0;
  transition: opacity 200ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px;
}
.Filters:hover .scrollbar {
  opacity: 1;
}

.Filters h2 {
  margin-top: 0;
}

.FilterGroup {
  width: 250px;
  height: 227px;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: none;
  margin: 0;
  padding: 10px 0 70px 0;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}

.FilterGroup ul {
  margin: 0;
  padding: 0;
}
.FilterGroup li {
  background: #444;
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 5px 10px 4px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}
.FilterGroup > li:last-child,
.FilterGroup > ul.HierarchicalTags:last-child {
  margin-bottom: 100px;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #444;
  margin-left: 24px;
}

.tooltip .tooltiptext {
  width: 80px;
  background-color: #444;
  color: #f0f0f0;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #444 transparent;
}
@media all and (max-width: 700px) {
  .Filters {
    margin: 0 -20px -20px;
  }
  .FiltersContent {
    padding-left: 30px;
  }
  .FilterGroup {
    width: 180px;
    height: 250px;
  }
}
