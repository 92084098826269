.Settings ul {
  padding: 0;
  list-style: none;
}
.Settings li {
  margin-bottom: 20px;
  display: flex;
}
.Settings li div {
  flex: 50%;
}
.Settings img {
  filter: invert(0.9);
}
.Settings input {
  background: #444;
  color: #fff;
  border: 0;
  padding: 10px;
  width: 100%;
}
.Settings .folder {
  cursor: pointer;
  margin: 5px 0 0 20px;
  vertical-align: middle;
}
.heading {
  margin-bottom: 15px !important;
}
.subHeading {
  margin: 0 0 40px 0 !important;
}
