.Onboarding div:last-child {
  padding-bottom: 0;
}
.Onboarding ul {
  padding: 0;
  list-style: none;
}
.Onboarding .message {
  margin-bottom: 40px;
}
.Onboarding li {
  margin-bottom: 20px;
  display: flex;
}
.Onboarding li div {
  flex: 50% 1;
}
.Onboarding .label {
  width: 33%;
  padding-top: 9px;
}
.Onboarding .label-boolean {
  width: 66.6%;
  padding-top: 0;
}
.Onboarding .field {
  width: 66.6%;
  padding-right: 1px;
}
.Onboarding .field-boolean {
  width: 33.3%;
  text-align: right;
}
.Onboarding .formContainer {
  min-height: 100%;
  height: 100%;
}
.Onboarding .formContainer form {
  height: 100%;
}
.Onboarding .formContainer form > div {
  min-height: 100%;
  padding-bottom: 40px;
}
@media all and (max-width: 700px) {
  .Onboarding .formContainer form > div {
    padding-bottom: 20px;
  }
}
.Onboarding input {
  border: 0;
  padding: 10px 12px;
  width: 100%;
}
.Onboarding select {
  border: 0;
  padding-left: 8px;
}
.Onboarding .error p {
  margin: 0;
}
.storageS3Preview {
  font-style: italic;
  margin: 0 0 0 33.3%;
}
