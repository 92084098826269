.ColorTags li {
  background: #383838;
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0);
  border: 1px solid #888;
  display: inline-block;
  cursor: default;
}
.ColorTags li.Red {
  background: rgb(229, 36, 36);
}
.ColorTags li.Orange {
  background: rgb(245, 133, 0);
}
.ColorTags li.Amber {
  background: rgb(234, 166, 30);
}
.ColorTags li.Yellow {
  background: rgb(240, 240, 39);
}
.ColorTags li.Lime {
  background: rgb(168, 228, 26);
}
.ColorTags li.Green {
  background: rgb(7, 215, 7);
}
.ColorTags li.Teal {
  background: rgb(16, 202, 155);
}
.ColorTags li.Turquoise {
  background: rgb(25, 225, 225);
}
.ColorTags li.Aqua {
  background: rgb(10, 188, 245);
}
.ColorTags li.Azure {
  background: rgb(30, 83, 249);
}
.ColorTags li.Blue {
  background: rgb(0, 0, 255);
}
.ColorTags li.Purple {
  background: rgb(127, 0, 255);
}
.ColorTags li.Orchid {
  background: rgb(190, 0, 255);
}
.ColorTags li.Magenta {
  background: rgb(233, 8, 200);
}
.ColorTags li.White {
  background: rgb(255, 255, 255);
}
.ColorTags li.Gray {
  background: rgb(124, 124, 124);
}
.ColorTags li.Black {
  background: rgb(0, 0, 0);
}
