html {
  height: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #1d1d1d;
  color: #ddd;
  height: 100%;
  max-height: 100%;
  font-size: 14px;
  line-height: 1.4;
  /* Prevent selecting text - more app like experience */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Disable overscroll bounce */
  overscroll-behavior-y: none;
}

a {
  outline: 0;
}
*:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

#root {
  height: 100%;
}

footer {
  background: #292929;
  padding: 5px 20px;
  display: none;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.flex-container-row {
  display: flex;
  flex-direction: row;
}
.main {
  flex-grow: 1;
  overflow: hidden;
  background: #1b1b1b;
  z-index: 1;
}

.App {
  text-align: center;
}

#filters {
  width: 300px;
  height: 100%;
  background: #222222;
  padding: 10px 20px;
  overflow: auto;
}
#filters h2 {
  font-size: 18px;
  color: #555;
}

#content {
  width: 100%;
  padding: 0;
  overflow: hidden;
  background: #1d1d1d;
  z-index: 0;
}
#content::-webkit-scrollbar {
  display: none;
}

.react-router-modal__modal {
  border: 0;
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
}
.react-router-modal__backdrop {
  background: rgba(0, 0, 0, 0.8);
}
input,
select {
  color: #fff !important;
}
select option {
  background: #1d1d1d;
  color: #fff;
}
button {
  border: 0;
  cursor: pointer;
}

@media only screen and (max-device-width: 1024px) {
  .react-router-modal__container {
    -webkit-transform: none;
    transform: none;
  }
  .react-router-modal__modal {
    width: 100%;
    height: 100%;
  }
}
